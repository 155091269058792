import { useContext, useEffect } from "react";

import { json, LinksFunction, LoaderArgs, MetaFunction } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
  useSearchParams,
} from "@remix-run/react";

import {
  Container,
  CssBaseline,
  GlobalStyles,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";

import { CookieBanner } from "./components/CookieBanner/CookieBanner";
import CrispLoader from "./components/Crisp";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { CtaIntersectionProvider } from "./components/Header/useIntersection";
import { GTM, GTMNoScript } from "./components/gtm";
import { CSSCacheContext } from "./styles/CSSContext";
import {
  fontBold,
  fontBoldItalic,
  fontRegular,
  fontRegularItalic,
  fontHeavy,
  fontHeavyItalic,
  fontLight,
  fontLightItalic,
  fontMedium,
  fontMediumItalic,
  fontSemiBold,
  fontSemiBoldItalic,
} from "./styles/fonts";
import globalStyle from "./styles/globalStyle";
import theme from "./styles/theme";
import {
  parseCookie,
  cookieRandomGenerator,
  COOKIE_BANNER_VALUES,
  COOKIE_BANNER_KEY,
} from "./utils/ABTesting";
import { GoogleApiKeyProvider } from "./utils/googlePlaceHook";
import { GoogleSessionTokenProvider } from "./utils/googleSessionToken";

/**
 * Meta found on the home
 *
 * <meta charset="UTF-8">
 * <meta name="viewport" content="width=device-width, initial-scale=1">
 * <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1">
 *
 * <meta name="description" content="Pendant vos absences, confiez-nous votre logement et recevez votre loyer tous les mois. Notre solution de sous-location est gratuite, légale et assurée.">
 *
 * <meta property="og:locale" content="fr_FR">
 * <meta property="og:type" content="website">
 * <meta property="og:title" content="Sous-location : Voyager sans payer son loyer - Leazly">
 * <meta property="og:description" content="Pendant vos absences, confiez-nous votre logement et recevez votre loyer tous les mois. Notre solution de sous-location est gratuite, légale et assurée.">
 * <meta property="og:url" content="https://leazly.fr/">
 * <meta property="og:site_name" content="Leazly">
 * <meta property="article:publisher" content="https://www.facebook.com/">
 * <meta property="article:modified_time" content="2023-01-04T11:23:31+00:00">
 * <meta property="og:image" content="wp-content/uploads/2020/07/screenshot.png">
 * <meta property="og:image:width" content="1064">
 * <meta property="og:image:height" content="774">
 * <meta property="og:image:type" content="image/png">
 * <meta name="twitter:card" content="summary_large_image">
 * <meta name="twitter:site" content="@leazly">
 * <meta name="twitter:label1" content="Durée de lecture estimée">
 * <meta name="twitter:data1" content="8 minutes">
 *
 * <meta name="facebook-domain-verification" content="">
 *
 */

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  viewport: "width=device-width,initial-scale=1",
  // title: "Sous-location : Voyager sans payer son loyer - Leazly",
  robots:
    "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
});

/**
 *
 * Links found on the home
 *
 * <link rel="profile" href="http://gmpg.org/xfn/11">
 *
 * <link rel="canonical" href="/">
 *
 * <link rel="dns-prefetch" href="//client.crisp.chat">
 * <link rel="dns-prefetch" href="//tarteaucitron.io">
 *
 * <link rel="alternate" type="application/rss+xml" title="Leazly » Flux" href="/feed/">
 * <link rel="alternate" type="application/rss+xml" title="Leazly » Flux des commentaires" href="/comments/feed/">
 *
 * <link rel="https://api.w.org/" href="/wp-json/">
 * <link rel="alternate" type="application/json" href="/wp-json/wp/v2/pages/40">
 * <link rel="EditURI" type="application/rsd+xml" title="RSD" href="/xmlrpc.php?rsd">
 * <link rel="wlwmanifest" type="application/wlwmanifest+xml" href="/wp-includes/wlwmanifest.xml">
 *
 * <link rel="shortlink" href="https://leazly.fr/">
 * <link rel="alternate" type="application/json+oembed" href="/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fleazly.fr%2F">
 * <link rel="alternate" type="text/xml+oembed" href="/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fleazly.fr%2F&amp;format=xml">
 *
 * <link rel="icon" href="wp-content/uploads/2022/12/favicon-86x86.png" data-spai-egr="1" sizes="32x32">
 * <link rel="icon" href="wp-content/uploads/2022/12/favicon.png" data-spai-egr="1" sizes="192x192">
 * <link rel="apple-touch-icon" href="wp-content/uploads/2022/12/favicon.png" data-spai-egr="1">
 *
 */

// export const links: LinksFunction = () => [];

// It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering.
// https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override

const globalStyles = <GlobalStyles styles={globalStyle} />;

export default () => {
  const { googleApiKey, crispWebsiteId, googleTagManagerId } =
    useLoaderData<typeof loader>();

  const [params] = useSearchParams();
  const objParams = Object.fromEntries(params);
  useEffect(useContext(CSSCacheContext), []);

  return (
    <html lang="fr-FR">
      <head>
        <GTM gtmId={googleTagManagerId} />
        <Meta />
        <Links />
        {globalStyles}
        <meta name="emotion" />
      </head>
      <ThemeProvider theme={theme}>
        <Stack component="body">
          <GTMNoScript gtmId={googleTagManagerId} />
          <CssBaseline />
          <GoogleApiKeyProvider value={googleApiKey}>
            <CtaIntersectionProvider>
              <GoogleSessionTokenProvider>
                <Outlet />
              </GoogleSessionTokenProvider>
            </CtaIntersectionProvider>
          </GoogleApiKeyProvider>
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
          <CookieBanner parameters={objParams} />
        </Stack>
      </ThemeProvider>
      <CrispLoader crispId={crispWebsiteId} />
    </html>
  );
};

export const links: LinksFunction = () => [
  {
    rel: "preload",
    href: fontRegular,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontBold,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontBoldItalic,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontHeavy,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontHeavyItalic,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontLight,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontLightItalic,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontMedium,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontMediumItalic,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontRegularItalic,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontSemiBold,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: fontSemiBoldItalic,
    as: "font",
    type: "font/opentype",
    crossOrigin: "anonymous",
  },
];

export const loader = async ({ request }: LoaderArgs) => {
  const cookieHeader = request.headers.get("Cookie");

  const { home: cookieValue } = parseCookie(cookieHeader);
  const { cookieBanner: cookieBannerValue } = parseCookie(cookieHeader);

  const cookieBannerVersion =
    cookieBannerValue || cookieRandomGenerator(COOKIE_BANNER_VALUES);
  const url = new URL(request.url);
  const referralCode = url.searchParams.get("referralCode");

  const headers = new Headers();

  headers.append(
    "Set-Cookie",
    !cookieValue ? `${COOKIE_BANNER_KEY}=${cookieBannerVersion};` : "",
  );
  headers.append(
    "Set-Cookie",
    referralCode ? `referralCode=${referralCode};` : "",
  );

  return json(
    {
      googleApiKey: process.env.CLIENT_GOOGLE_API_KEY,
      crispWebsiteId: process.env.CRISP_WEBSITE_ID,
      googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
      reCaptchaId: process.env.RECAPTCHA_GOOGLE_KEY,
      cookieBannerVersion,
    },
    {
      headers,
    },
  );
};

/**
 * root env variables are constants and should not transit again
 * @see https://remix.run/docs/en/v1/route/should-revalidate#never-reloading-the-root
 */
export const shouldRevalidate = () => false;

export const CatchBoundary: React.FC = () => {
  const caught = useCatch();
  useEffect(useContext(CSSCacheContext), []);
  return (
    <html lang="fr-FR">
      <head>
        <Meta />
        <Links />
        {globalStyles}
        <meta name="emotion" />
      </head>
      <ThemeProvider theme={theme}>
        <body>
          <CssBaseline />
          <Header static />
          <Container component="section">
            <Stack py={16} textAlign="center">
              <Typography component="h1">{caught.statusText}</Typography>
              {caught.status}
            </Stack>
          </Container>
          <Footer />
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </body>
      </ThemeProvider>
    </html>
  );
};
