import { useState, useEffect, useCallback } from "react";

import { useMatches } from "@remix-run/react";

import {
  Box,
  Button,
  Grid,
  Link,
  Modal,
  Stack,
  Typography,
} from "@mui/material";

import Cookies from "js-cookie";

import { generateUniqueID, getDataLayer } from "~/utils/dataLayer";

import cookieBannerImg1 from "../../public/imgs/cookie-banner1.png";
import { KeyText } from "../Header/Logo";
import { CustomCookieBanner } from "./CustomCookieBanner";

const persistAsCookies = [
  "utm_source",
  "utm_term",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "fbclid",
  "gclid",
  "awc",
];

export const analyticsCookies = ["analytics_storage"] as const;
export const addsCookies = ["ad_storage"] as const;

const allCookies = [...analyticsCookies, ...addsCookies] as const;

const convertParamsToCookies = (parameters: Record<string, string>) => {
  Object.entries(parameters)
    .filter(([key]) => persistAsCookies.indexOf(key) !== -1)
    .map(([cookieName, value]) => {
      if (Cookies.get(cookieName) === undefined) {
        if (cookieName === "awc" && Cookies.get("awc") === undefined) {
          Cookies.set("awc", decodeURI(value));
          const awinOrderReference = generateUniqueID();
          Cookies.set("awinOrderReference", decodeURI(awinOrderReference));
          getDataLayer().push({
            event: "awinOrderReference",
            awinOrderReference,
          });
        } else {
          Cookies.set(cookieName, decodeURI(value));
        }
      }
    });
};

const acceptAllCookies = () => {
  allCookies.forEach((cookie) => {
    Cookies.set(cookie, "granted");
  });
};

const rejectAllCookies = () => {
  allCookies.forEach((cookie) => {
    Cookies.set(cookie, "denied");
  });
};

export const CookieBanner: React.FC<{ parameters: Record<string, string> }> = ({
  parameters = {},
}) => {
  const [cookieAccepted, setCookieAccepted] = useState<boolean | undefined>(
    undefined,
  );

  const [cookieCustomization, setCookieCustomization] = useState(false);

  useEffect(() => {
    const consentCookie = Cookies.get("analytics_storage");

    if (consentCookie) {
      setCookieAccepted(consentCookie === "granted");
      if (consentCookie === "granted") {
        convertParamsToCookies(parameters);
      }
    }
  }, []);

  const onAccept = useCallback(() => {
    acceptAllCookies();
    setCookieAccepted(true);
    convertParamsToCookies(parameters);
    getDataLayer().push({ event: "custom_consent" });
  }, []);

  const onReject = useCallback(() => {
    rejectAllCookies();
    setCookieAccepted(false);
    getDataLayer().push({ event: "custom_consent" });
  }, []);

  const onCustomCookies = useCallback(() => {
    setCookieCustomization(true);
  }, []);

  const onGoBack = useCallback(() => {
    setCookieCustomization(false);
  }, []);

  const onClose = useCallback(() => {
    setCookieAccepted(true);
  }, []);

  const matches = useMatches();

  const {
    data: { cookieBannerVersion },
  } = matches[0];

  return cookieAccepted === undefined ? (
    <Modal
      open
      sx={{
        zIndex: 1000001,
        backgroundColor: { xs: "rgba(0, 0, 0, 0.5)", sm: "rgba(0, 0, 0, 0.5)" },
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100vw"
        height="100vh"
      >
        <Stack
          px={{ xs: 2, sm: 4, md: 12, lg: 20, xl: 30 }}
          position="absolute"
          width={{ xs: "100vw", sm: "80vw", md: "69vw" }}
          maxHeight={{ xs: "90vh" }}
        >
          <Box
            flexGrow={1}
            data-testid="cookies-banner"
            bgcolor="white"
            sx={{
              paddingBottom: { xs: "38px", sm: "32px" },
              paddingLeft: { xs: "18px", sm: "38px" },
              paddingRight: { xs: "18px", sm: "38px" },
              paddingTop: { xs: "24px", sm: "30px" },
              overflowY: "auto",
            }}
            borderRadius={8}
            position="relative"
          >
            {cookieBannerVersion === "cookieBannerB" ? (
              <Box
                component="img"
                src={cookieBannerImg1}
                display={cookieCustomization ? "none" : "block"}
                sx={{
                  position: "absolute",
                  top: { xs: "30px", sm: "20px" },
                  right: "40px",
                  height: "80px",
                }}
              />
            ) : (
              <>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="right"
                  alignItems="flex-end"
                  sx={{
                    marginRight: { xs: "0px", sm: "-12px" },
                  }}
                >
                  <Link
                    component="button"
                    variant="body2"
                    onClick={onReject}
                    data-testid="reject-cookies"
                    aria-label="Reject cookies"
                    sx={(theme) => ({
                      color: theme.palette.gray.main,
                      textDecorationColor: theme.palette.gray.main,
                      fontSize: { xs: "8px", sm: "10px" },
                      marginRight: { xs: "8px", sm: "0px" },
                    })}
                  >
                    Continuer sans accepter
                  </Link>
                </Stack>
                <Box display="flex" justifyContent="center">
                  {!cookieCustomization && <KeyText />}
                </Box>
              </>
            )}
            <Grid item xs={12} className="toto">
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="right"
                alignItems="flex-end"
                sx={{
                  marginRight: { xs: "0px", sm: "-12px" },
                }}
              >
                <Link
                  display={{
                    xs: `${cookieCustomization ? "block" : "none"}`,
                  }}
                  component="button"
                  variant="body2"
                  onClick={onReject}
                  data-testid="reject-cookies-custom"
                  aria-label="Reject cookies"
                  sx={(theme) => ({
                    color: theme.palette.gray.main,
                    textDecorationColor: theme.palette.gray.main,
                    fontSize: { xs: "8px", sm: "10px" },
                    marginTop: { xs: "-6px", sm: "4px" },
                    marginRight: { xs: "8px", sm: "0px" },
                  })}
                >
                  Continuer sans accepter
                </Link>
              </Stack>
              {cookieCustomization && (
                <Box textAlign="center" pb={2}>
                  <KeyText />
                </Box>
              )}
            </Grid>
            <Grid
              container
              alignItems="center"
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {cookieCustomization ? (
                <CustomCookieBanner onGoBack={onGoBack} onClose={onClose} />
              ) : (
                <>
                  <Grid item xs={9}>
                    {cookieBannerVersion === "cookieBannerB" && (
                      <Box pl="20px" pt="20px">
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          sx={(theme) => ({
                            color: theme.palette.navy.main,
                            textAlign: "left",
                          })}
                        >
                          Qui n'aime pas les cookies ?
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Typography
                      sx={(theme) => ({
                        color: theme.palette.gray.main,
                        textAlign: "justify",
                        fontSize: "14px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: `${
                          cookieBannerVersion === "cookieBannerB"
                            ? "16px"
                            : "4px"
                        }`,
                      })}
                      variant="body1"
                      gutterBottom
                    >
                      {cookieBannerVersion === "cookieBannerB" ? (
                        <>
                          Lorsque vous naviguez sur notre site, Leazly et ses
                          partenaires utilisent des cookies pour améliorer votre
                          expérience. Vous êtes libre de continuer votre
                          navigation{" "}
                          <Link
                            component="button"
                            variant="body2"
                            onClick={onReject}
                            data-testid="reject-cookies"
                            aria-label="Reject cookies"
                            sx={(theme) => ({
                              fontSize: "14px",
                              color: theme.palette.gray.main,
                              textDecorationColor: theme.palette.gray.main,
                            })}
                          >
                            sans les accepter.
                          </Link>{" "}
                          Cependant, en cliquant sur "Paramétrer", vous avez la
                          possibilité d'indiquer vos préférences.
                        </>
                      ) : (
                        <>
                          Lorsque vous naviguez sur notre site, Leazly et ses
                          partenaires utilisent des cookies et autres traceurs.
                          En cliquant sur “Paramétrer”, vous pouvez indiquer vos
                          préférences.
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction={{ xs: "column-reverse", sm: "row" }}
                      gap={{ xs: 2, sm: 4 }}
                      justifyContent="center"
                      paddingLeft="20px"
                      paddingRight="20px"
                    >
                      <Button
                        variant="outlined"
                        aria-label="Custom cookies"
                        data-testid="custom-cookies"
                        onClick={onCustomCookies}
                        color="secondary"
                      >
                        Paramétrer
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="Accept cookies"
                        onClick={onAccept}
                        data-testid="accept-cookies"
                      >
                        {cookieBannerVersion === "cookieBannerB"
                          ? "J'aime les cookies"
                          : "J'accepte"}
                      </Button>
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Stack>
      </Stack>
    </Modal>
  ) : null;
};
