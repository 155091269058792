import { useEffect } from "react";

import { Crisp } from "crisp-sdk-web";

const CrispLoader: React.FC<{ crispId?: string }> = ({ crispId }) => {
  useEffect(() => {
    if (!crispId) {
      return;
    }
    Crisp.configure(crispId);
  }, [crispId]);
  return null;
};

export default CrispLoader;
