import { useState, useCallback } from "react";

import {
  Grid,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  Stack,
} from "@mui/material";

import Cookies from "js-cookie";

import { getDataLayer } from "~/utils/dataLayer";

import { addsCookies, analyticsCookies } from "./CookieBanner";

export const CustomCookieBanner: React.FC<{
  onGoBack: () => void;
  onClose: () => void;
}> = ({ onGoBack, onClose }) => {
  const [cookiesStatus, setCookiesStatus] = useState<Record<string, boolean>>({
    analytics_storage: true,
    ad_storage: true,
    functional_tools: true,
  });

  const onToggleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCookiesStatus({ ...cookiesStatus, [name]: event.target.checked });
    };

  const handleClose = useCallback(() => {
    analyticsCookies.forEach((cookie) => {
      Cookies.set(
        cookie,
        cookiesStatus.analytics_storage ? "granted" : "denied",
      );
    });

    addsCookies.forEach((cookie) => {
      Cookies.set(cookie, cookiesStatus.ad_storage ? "granted" : "denied");
    });

    getDataLayer().push({ event: "custom_consent" });

    onClose();
  }, [cookiesStatus]);

  return (
    <>
      <Grid item xs={12} data-testid="custom-cookies-list">
        <Stack gap={4} alignItems="left" padding={{ xs: "0px" }}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.gray.main,
              paddingLeft: "16px",
              paddingRight: "32px",
              marginBottom: { xs: "-26px", sm: "0px" },
              textAlign: "justify",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: "light",
            })}
            variant="body1"
            gutterBottom
          >
            Lorsque vous naviguez sur notre site, Leazly et ses partenaires
            utilisent des cookies et autres traceurs.
          </Typography>
          <IndividualCookieManager
            name="functionnal_tools"
            title="Cookies et outils fonctionnels"
            checked={cookiesStatus.functional_tools}
            onChange={onToggleChange}
            disbaled
          />
          <Typography
            sx={(theme) => ({
              color: theme.palette.gray.main,
              paddingLeft: "16px",
              paddingRight: "32px",
              marginTop: "-34px",
              marginBottom: { xs: "-26px", sm: "0px" },
              textAlign: "justify",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: "light",
            })}
            variant="body1"
            gutterBottom
          >
            Ces cookies et outils fonctionnels sont indispensables à la
            navigation sur le site et au respect de vos préférences en matière
            de confidentialité pour les cookies non nécessaires au
            fonctionnement du site. Ils ne peuvent donc pas être désactivés.
          </Typography>
          <IndividualCookieManager
            name="analytics_storage"
            title="Cookies analytiques"
            checked={cookiesStatus.analytics_storage}
            onChange={onToggleChange}
          />
          <Typography
            sx={(theme) => ({
              color: theme.palette.gray.main,
              paddingLeft: "16px",
              paddingRight: "32px",
              marginTop: "-34px",
              marginBottom: { xs: "-26px", sm: "0px" },
              textAlign: "justify",
              fontSize: { xs: "12px", sm: "14px" },
            })}
            variant="body1"
            gutterBottom
          >
            Ces cookies sont utilisés pour mesurer l’audience et la performance
            du site, personnaliser le contenu, personnaliser la publicité,
            développer et améliorer nos produits.
          </Typography>
          <IndividualCookieManager
            name="ad_storage"
            title="Cookies publicitaires"
            checked={cookiesStatus.ad_storage}
            onChange={onToggleChange}
          />
          <Typography
            sx={(theme) => ({
              color: theme.palette.gray.main,
              paddingLeft: "16px",
              paddingRight: "32px",
              marginTop: "-34px",
              textAlign: "justify",
              fontSize: { xs: "12px", sm: "14px" },
            })}
            variant="body1"
            gutterBottom
          >
            Ces cookies sont utilisés pour analyser vos centres d’intérêts afin
            de vous proposer avec nos partenaires des publicités personnalisées.
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack
          gap={{ xs: 2, sm: 4 }}
          justifyContent="center"
          direction={{ xs: "column-reverse", sm: "row" }}
        >
          <Button
            variant="outlined"
            aria-label="Go back"
            onClick={onGoBack}
            data-testid="go-back-cookies"
            color="secondary"
          >
            Retour
          </Button>
          <Button
            variant="contained"
            aria-label="Close cookies banner"
            data-testid="close-cookies"
            onClick={handleClose}
            color="primary"
          >
            Accepter et fermer
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

const IndividualCookieManager: React.FC<{
  name: string;
  title: string;
  checked: boolean;
  disbaled?: boolean;
  onChange: (
    name: string,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ name, title, checked, onChange, disbaled }) => {
  return (
    <FormControlLabel
      disabled={disbaled}
      control={
        <Switch
          name={name}
          checked={checked}
          onChange={onChange(name)}
          data-testid={`switch-cookie-${name}`}
        />
      }
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        "& .MuiTypography-root": {
          color: theme.palette.secondary.main,
        },
        "&.Mui-disabled .MuiTypography-root": {
          color: theme.palette.secondary.main,
        },
        padding: { xs: "0px" },
      })}
      labelPlacement="start"
      label={title}
    />
  );
};
