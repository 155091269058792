import { useEffect } from "react";

import { getDataLayer } from "~/utils/dataLayer";

export const GTM: React.FC<{ gtmId?: string }> = ({ gtmId }) => {
  useEffect(() => {
    getDataLayer().push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });

    if (gtmId) {
      const s = window.document.createElement("script");

      s.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      s.async = true;
      const p = window.document.getElementsByTagName("script")[0];

      p.parentNode?.insertBefore(s, p);
    }
  }, [gtmId]);

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: "window.dataLayer=window.dataLayer || []; ",
      }}
    ></script>
  );
};

export const GTMNoScript: React.FC<{ gtmId?: string }> = ({ gtmId }) =>
  gtmId ? (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
  ) : null;
